import React, { useState, useEffect } from 'react';
import ModalWrapper from '@components/modalComponents/NewModal';
import FormTitleSection from '@components/modalComponents/FormTitleSection';
import request, { multipartHeader } from '@request';
import { toast } from 'react-toastify';
import Button from '@components/Button/Button';
import SubmitSuccess from '@components/SubmitSuccess';
import { useFormik } from 'formik';
import styled from 'styled-components';
import SelectWrapper from '@components/formInputs/selectWrapper';
import TextAreaInput from '@components/formInputs/TextAreaInput';

export default function CreateDataset({
  onClick,
  currData,
  createDatasetEndpoint,
  editDatasetEndpoint,
  refetch,
}) {
  const [data] = useState(currData);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = async (value) => {
    const formData = new FormData();
    for (const key in value) {
      formData.append(key, value[key]);
    }

    setLoading(true);
    const requestMethod = data?.id ? 'patch' : 'post';
    setLoading(true);

    try {
      const res = await request[requestMethod](
        data?.id ? editDatasetEndpoint : createDatasetEndpoint,
        formData,
        multipartHeader
      );

      if (res.status === 200 || res.status === 201) {
        // Assuming successful upload status code
        refetch();
        setSubmitted(true);
        toast.success('dataset created successully');
      } else {
        throw new Error('could not create dataset');
      }
    } catch (err) {
      console.error('Error :', err.response);
      toast.error('Error creating dataset. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    sku: '',
    description: '',
    use: '',
    comments: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const onLoad = () => {
    if (data) {
      const fields = Object.keys(initialValues);
      fields.forEach((field) => {
        formik.setFieldValue(field, data[field], false);
        // }
      });
    }
  };

  useEffect(() => {
    if (data) {
      onLoad();
    }
  }, [data]);

  return (
    <ModalWrapper width="50%" height="90%">
      <FormTitleSection
        onClick={onClick}
        title={`Edit dataset-${data?.drug_name}-${data?.generic_name}-${data?.strength}${data?.unit}-${data?.mode}`}
      />
      {submitted ? (
        <SubmitSuccess
          message={
            data?.id
              ? `${data?.drug_name}-${data?.generic_name}-${data?.strength}${data?.unit}-${data?.mode} edited`
              : `${formik.values.sku}-${formik?.values?.drug_name} dataset created`
          }
        />
      ) : (
        <>
          <FormWrapper onSubmit={formik.handleSubmit}>
            <SelectWrapper label="Description" error={formik.errors.use}>
              <TextAreaInput
                rows="15"
                placeholder="Description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.errors.description}
              />
            </SelectWrapper>
            <SelectWrapper label="Drug Use" error={formik.errors.use}>
              <TextAreaInput
                rows="15"
                name="use"
                value={formik.values.use}
                onChange={formik.handleChange}
                error={formik.errors.use}
              />
            </SelectWrapper>
            <SelectWrapper label="Comment" error={formik.errors.comments}>
              <TextAreaInput
                rows="15"
                name="comments"
                value={formik.values.comments}
                onChange={formik.handleChange}
                error={formik.errors.comments}
              />
            </SelectWrapper>
            <div className="form--submit">
              <div className="modal-footer">
                <Button type="submit">
                  {loading ? 'Saving...' : 'Add Dataset'}
                </Button>
              </div>
            </div>
          </FormWrapper>
        </>
      )}
    </ModalWrapper>
  );
}

const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  scrollbar-width: 20px;
  scrollbar-color: #2254d3 #eee;

  & .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .img-wrapper {
    width: 100%;
    display: flex;
    gap: 5px;
    .img {
      width: 20%;
    }
    .textareai {
      width: 80%;
      /* height: 100px; */
    }
  }
`;
